// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-404-md": () => import("./../src/pages/404.md" /* webpackChunkName: "component---src-pages-404-md" */),
  "component---src-pages-lavori-con-drone-md": () => import("./../src/pages/lavori-con-drone.md" /* webpackChunkName: "component---src-pages-lavori-con-drone-md" */),
  "component---src-pages-lavori-topografici-e-catastali-md": () => import("./../src/pages/lavori-topografici-e-catastali.md" /* webpackChunkName: "component---src-pages-lavori-topografici-e-catastali-md" */),
  "component---src-pages-privacy-policy-md": () => import("./../src/pages/privacy-policy.md" /* webpackChunkName: "component---src-pages-privacy-policy-md" */)
}

